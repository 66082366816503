<template>
    <div class="container" id="LookEnrollDetail">
        <el-page-header @back="$router.back()" content="查看赛事报名设置信息"></el-page-header>
        <div class="LookEnrollDetail_content">
            <el-descriptions title="基本信息" :column="1" :labelStyle="labelStyle">
                <el-descriptions-item label="赛事名称">{{LeagueBaseInfo.SubLeagueName}}</el-descriptions-item>
                <el-descriptions-item label="比赛时间">
                    <span v-if="LeagueBaseInfo.GameBeginTime && LeagueBaseInfo.GameEndTime">{{LeagueBaseInfo.GameBeginTime | dateFormat('yyyy-MM-dd HH:mm:ss')}} ~ {{LeagueBaseInfo.GameEndTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}</span>
                    <span v-else>未定</span>
                </el-descriptions-item>
                <el-descriptions-item label="报名时间">
                    {{BaseInfo.EnrollBeginTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}
                    ~
                    <template v-if="BaseInfo.EnrollEndTime">{{BaseInfo.EnrollEndTime | dateFormat('yyyy-MM-dd HH:mm:ss')}}</template>
                    <template v-else>无期限</template>
                </el-descriptions-item>
                <el-descriptions-item label="报名须知">
                    <Editor :value="BaseInfo.EnrollNotice" v-if="BaseInfo.EnrollNotice" disabled></Editor>
                    <span v-else>无</span>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="报名限制设置" :column="1" :labelStyle="{...labelStyle, width: '130px'}" v-if="BaseInfo.EnrollOptions">
                <el-descriptions-item label="运动员报名限制">
                    <div>
                        <p v-if="BaseInfo.EnrollOptions.athlete_enroll_limit == 0">可以同时报名团体组别和个人/双人组别，也可以只报名一种。</p>
                        <p v-else-if="BaseInfo.EnrollOptions.athlete_enroll_limit == 1">必须先报名团体组别，才能报名个人/双人组别;也可以只报名团体组别。</p>
                        <p v-else-if="BaseInfo.EnrollOptions.athlete_enroll_limit == 2">不能同时报名团体组别和个人/双人组别，只能报名其中一种。</p>
                        <p>最多能同时报名的团体组别数量: {{BaseInfo.EnrollOptions.athlete_enroll_teamgroup_num_max || '不限'}}</p>
                        <p>最多能同时报名的个人/双人组别数量: {{BaseInfo.EnrollOptions.athlete_enroll_single_or_doublegroup_num_max || '不限'}}</p>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="代表队报名限制">
                    <div>
                        <p v-if="BaseInfo.EnrollOptions.dpt_enroll_limit == 0">可以同时报名团体组别和个人/双人组别，也可以只报名一种。</p>
                        <p v-else-if="BaseInfo.EnrollOptions.dpt_enroll_limit == 1">必须先报名团体组别，才能报名个人/双人组别;也可以只报名团体组别。</p>
                        <p>最多能同时报名的团体组别数量: {{BaseInfo.EnrollOptions.dpt_enroll_teamgroup_num_max || '不限'}}</p>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="队员信息设置" :labelStyle="labelStyle" v-if="BaseInfo.FieldsOptions">
                <el-descriptions-item label="球衣号码">
                    <span v-if="BaseInfo.FieldsOptions.jersey_number == 0">不使用</span>
                    <span v-else-if="BaseInfo.FieldsOptions.jersey_number == 1">使用（仅对球员）</span>
                </el-descriptions-item>
                <el-descriptions-item label="肖像照">
                    <span v-if="BaseInfo.FieldsOptions.portrait_photo == 0">不要求必须上传</span>
                    <span v-else-if="BaseInfo.FieldsOptions.portrait_photo == 1">运动员必须上传，其他身份不要求必须上传</span>
                    <span v-else-if="BaseInfo.FieldsOptions.portrait_photo == 9">所有队员必须上传</span>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions title="队员身份" :column="1" :labelStyle="labelStyle">
                <el-descriptions-item v-for="(item,index) in RoleInfo" :key="index"
                    :label="item.RoleName" 
                >
                    <span>最少{{item.PeopleMin ? ` ${item.PeopleMin} 人` : '（不限）'}}，</span>
                    <span>最多{{item.PeopleMax ? ` ${item.PeopleMax} 人`: '（不限）'}}</span>
                    <span>{{item.CanPartTime == 1 ? '，可兼任运动员' : ''}}</span>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <el-descriptions :column="1" :labelStyle="{...labelStyle, 'width':'130px'}"
                v-for="(item,index) in GroupInfo" :key="index"
                :title="item.LeagueGroupName"
            >
                <el-descriptions-item label="运动员性别限制">
                    <span v-if="[1].includes(item.GenderLimit)">只限男子</span>
                    <span v-if="[2].includes(item.GenderLimit)">只限女子</span>
                    <span v-if="item.GenderLimit == 4">一男一女</span>
                    <span v-if="item.GenderLimit == 3">男女不限</span>
                </el-descriptions-item>
                <el-descriptions-item :label="([3,4].includes(item.GenderLimit)?'男':'')+'运动员年龄限制'" v-if="[1,3,4].includes(item.GenderLimit)">
                    <span>最早出生：{{item.MaleBirthdayMin ? (dateFormat(item.MaleBirthdayMin, 'yyyy年MM月dd日')) : '不限'}}，</span>
                    <span>最晚出生：{{item.MaleBirthdayMax ? (dateFormat(item.MaleBirthdayMax, 'yyyy年MM月dd日')) : '不限'}}</span>
                </el-descriptions-item>
                <el-descriptions-item :label="([3,4].includes(item.GenderLimit)?'女':'')+'运动员年龄限制'" v-if="[2,3,4].includes(item.GenderLimit)">
                    <span>最早出生：{{item.FemaleBirthdayMin ? (dateFormat(item.FemaleBirthdayMin, 'yyyy年MM月dd日')) : '不限'}}，</span>
                    <span>最晚出生：{{item.FemaleBirthdayMax ? (dateFormat(item.FemaleBirthdayMax, 'yyyy年MM月dd日')) : '不限'}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="运动员人数限制" v-if="item.GroupType != 3" :key="1">
                    <span>最少{{item.AthletesMin ? ` ${item.AthletesMin} 人` : '（不限）'}}，</span>
                    <span>最多{{item.AthletesMax ? ` ${item.AthletesMax} 人` : '（不限）'}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="运动员组合对数限制" v-else :key="2">
                    <span>最少{{item.AthletesMin ? ` ${item.AthletesMin} 对` : '（不限）'}}，</span>
                    <span>最多{{item.AthletesMax ? ` ${item.AthletesMax} 对` : '（不限）'}}</span>
                </el-descriptions-item>
                <template v-if="item.GroupType != 3 && item.GenderLimit == 3">
                    <el-descriptions-item label="其中，男运动员人数" :labelStyle="{'width':'170px','justify-content': 'end'}">
                        <span>最少{{item.MaleAthletesMin ? ` ${item.MaleAthletesMin} 人` : '（不限）'}}，</span>
                        <span>最多{{item.MaleAthletesMax ? ` ${item.MaleAthletesMax} 人` : '（不限）'}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="女运动员人数" :labelStyle="{'width':'170px','justify-content': 'end'}">
                        <span>最少{{item.FemaleAthletesMin ? ` ${item.FemaleAthletesMin} 人` : '（不限）'}}，</span>
                        <span>最多{{item.FemaleAthletesMax ? ` ${item.FemaleAthletesMax} 人` : '（不限）'}}</span>
                    </el-descriptions-item>
                </template>
                <el-descriptions-item label="报名方式">
                    <span v-if="[1,3].includes(item.EnrollWay)">代表队报名</span>
                    <span v-if="[2,3].includes(item.EnrollWay)">，个人报名</span>
                </el-descriptions-item>
                <el-descriptions-item label="组别说明">
                    <el-input type="textarea" resize="none" :rows="3" :value="item.LeagueGroupDesc" v-if="item.LeagueGroupDesc" style="width: 100%;max-width:400px;"></el-input>
                    <span v-else>无说明</span>
                </el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            
            <el-descriptions title="法律文件" :column="1" :labelStyle="{...labelStyle,'width':'80px'}">
                <template v-if="InstrumentInfo && InstrumentInfo.length > 0">
                    <el-descriptions-item v-for="(item,index) in InstrumentInfo" :key="index"
                    :label="'文件'+(index+1)">
                    <a :href="item.InstrumentUrl" style="text-decoration: none;" title="点击下载">{{item.InstrumentName}}</a>
                    </el-descriptions-item>
                </template>
                <template>
                    <p>未上传法律文件</p>
                </template>
            </el-descriptions>
        </div>
    </div>
</template>
<script>
import {
    GetLeagueEnrollDetail
} from '@/api'
import {
    dateFormat
} from '@/assets/js';
import Editor from '@/components/Editor';
export default {
    components: { 
        Editor
    },
    data(){
        var leagueEnrollId = parseInt(this.$route.params.leagueEnrollId),
            subLeagueId = parseInt(this.$route.params.subLeagueId);
        return {
            Id: isNaN(leagueEnrollId) ? 0 : leagueEnrollId,
            subLeagueId: isNaN(subLeagueId) ? 0 : subLeagueId,
            labelStyle: {'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'},
            LeagueBaseInfo:{},
            BaseInfo:{},
            RoleInfo:[],
            GroupInfo:[],
            InstrumentInfo:[]
        }
    },
    created(){
        GetLeagueEnrollDetail({
            LeagueEnrollId: this.Id,
            SubLeagueId: this.subLeagueId
        }).then(data=>{
            if(data.Code == 0){
                var {
                    LeagueBaseInfo,
                    BaseInfo,
                    RoleInfo,
                    GroupInfo,
                    InstrumentInfo
                } = data.SettingDetail;
                this.LeagueBaseInfo = LeagueBaseInfo;
                this.BaseInfo = BaseInfo;
                this.RoleInfo = RoleInfo;
                this.GroupInfo = GroupInfo;
                this.InstrumentInfo = InstrumentInfo;
            }
        })
    },
    methods:{
        dateFormat
    }
}
</script>
<style>
#LookEnrollDetail .el-descriptions-item__content{
    width: 100%;
}
#LookEnrollDetail .el-descriptions-item__content .editor{
    width: 100%;
}
#LookEnrollDetail .editor .tox-editor-header{
    display: none;
}
#LookEnrollDetail .el-card .el-card__body{
    padding: 20px 10px 0 0px !important;
}
#LookEnrollDetail .data{
    color:#409EFF;
    font-weight:blod
}
#LookEnrollDetail .nullData{
    color:#f56c6c;
    font-weight:blod
}
</style>